import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
const Footer = () => {

  return (
        <footer className="footer">
            {/* <div className="footer__main">
              <div className="footer__main--left"></div>
              <div className="footer__main--right"></div>
            </div> */}
            <div className="footer__copyright">
              &copy; 2022 - 2024 The Detailing Ghost | All Rights Reserved
            </div>
            <ul>
              <li><AnchorLink to="/terms-conditions">Terms &amp; Conditions</AnchorLink></li>
            </ul>
        </footer>

  )
}

export default Footer